import React, { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import MobileHeaderSmall from '../../components/MobileHeaderSmall/MobileHeaderSmall';
import { ArchiveData } from '../../data/ArchiveData';
import './Archive.scss';
import { Link, useNavigate } from 'react-router-dom';

const Archive = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div className="archive">
      <Header main={false} />
      <MobileHeaderSmall main={false} />
      <div className="container">
        <p className="title">Arhiva</p>
        <p className="subtitle">SRPSKI DANI OSIGURANJA</p>
        <div className="data-list">
          {ArchiveData.map((el: any, i: number) => {
            return (
              <div key={i} className="single-box">
                <div className="image-wrapper">
                  <Link to={el.link}>
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url('${el.image}')`
                      }}></div>
                  </Link>
                </div>
                <p className="single-box-title">{el.title}</p>
                <p className="single-box-text">{`${el.text.substring(0, 200)}${
                  el.text.length > 200 ? '...' : ''
                }`}</p>
                <div className="btn-cotaniner">
                  <Link to={el.link} className={'btn'}>
                    POGLEDAJTE VIŠE
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Archive;
