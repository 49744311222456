import './ImageModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const ImageModal = (props: any) => {
  return (
    <div className="image-modal">
      <button onClick={props.handleLeftSlide} className="left-btn">
        <FontAwesomeIcon onClick={props.handleModal} icon={faAngleLeft} className="icon" />
      </button>
      <img className="img" src={props.image} />
      <button onClick={props.handleRightSlide} className="right-btn">
        <FontAwesomeIcon onClick={props.handleModal} icon={faAngleRight} className="icon" />
      </button>
    </div>
  );
};

export default ImageModal;
