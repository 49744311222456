import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import './ArchiveSingleView.scss';
import { useParams } from 'react-router-dom';
import { ArchiveData } from '../../data/ArchiveData';
import { Link } from 'react-router-dom';
import ImageModal from '../../components/ImageModal/ImageModal';
import Overlay from '../../components/Overlay/Overlay';
import MobileHeaderSmall from '../../components/MobileHeaderSmall/MobileHeaderSmall';

const ArchiveSingleView = () => {
  const { id } = useParams();
  const [data, setData] = useState<any>(undefined);
  const [showMore, setShowMore] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(false);
  const [index, setIndex] = useState<any>(null);
  const [image, setImage] = useState(undefined);

  useEffect(() => {
    ArchiveData.forEach((el: any) => {
      if (el.id === Number(id)) {
        return setData(el);
      }
    });
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openModal]);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleCurrentImage = (index: number) => {
    setIndex(index);
    setImage(data.imageArray[index]);
  };

  const handleLeftSlide = () => {
    const imageArray = [...data.imageArray];
    if (index === 0) {
      setImage(imageArray[imageArray.length - 1]);
      setIndex(imageArray.length - 1);
    } else {
      setImage(imageArray[index - 1]);
      setIndex(index - 1);
    }
  };

  const handleRightSlide = () => {
    const imageArray = [...data.imageArray];
    if (index === imageArray.length - 1) {
      setImage(imageArray[0]);
      setIndex(0);
    } else {
      setImage(imageArray[index + 1]);
      setIndex(index + 1);
    }
  };

  return (
    <div className="single-view">
      <Header main={false} />
      <MobileHeaderSmall main={false} />
      {openModal && (
        <>
          <ImageModal
            currentImage={currentImage}
            handleLeftSlide={handleLeftSlide}
            handleRightSlide={handleRightSlide}
            image={image}
          />
          <Overlay handleModal={handleModal} />
        </>
      )}
      <div className="container">
        <p className="title">Arhiva</p>
        <p className="subtitle">{data?.title}</p>
        <p className="text">{data?.text}</p>
        <div className="image-list">
          {data?.imageArray?.map((el: any, i: number) => {
            if (!showMore) {
              if (i + 1 <= 8) {
                return (
                  <div key={i} className="image-wrapper">
                    <div
                      className="img"
                      onClick={() => {
                        handleModal();
                        handleCurrentImage(i);
                      }}
                      style={{
                        backgroundImage: `url('${el}')`
                      }}></div>
                  </div>
                );
              }
            } else {
              return (
                <div key={i} className="image-wrapper">
                  <div
                    className="img"
                    onClick={() => {
                      handleModal();
                      handleCurrentImage(i);
                    }}
                    style={{
                      backgroundImage: `url('${el}')`
                    }}></div>
                </div>
              );
            }
          })}
        </div>
        <div className="btn-container">
          <button onClick={handleShowMore} className="btn">
            {!showMore ? 'PRIKAŽI VIŠE' : 'PRIKAŽI MANJE'}
          </button>
        </div>
        <div className="btn-container-down">
          <Link to="/archive" className="btn-back">
            POVRATAK NA ARHIVU
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ArchiveSingleView;
