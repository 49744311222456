import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import MobileHeaderSmall from '../../components/MobileHeaderSmall/MobileHeaderSmall';
import './Contact.scss';

const Contact = () => {
  return (
    <div className="contact">
      <Header main={false} />
      <MobileHeaderSmall main={false} />
      <p>CONTACT</p>
      <Footer />
    </div>
  );
};

export default Contact;
