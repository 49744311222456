import './Header.scss';
import Navigation from '../Navigation/Navigation';
import UOSLogo from '../../assets/UOS-logo.png';
import UOSLogoColor from '../../assets/UOS-logo-color.png';
import { Link } from 'react-router-dom';

const Header = (props: any) => {
  return (
    <div className="header">
      <div className={props.main ? 'inner-container' : 'inner-container border'}>
        <div className="navigation">
          <div className="image-container">
            <Link to={'/'}>
              <img className="image" src={UOSLogoColor} />
            </Link>
          </div>
          <Navigation />
        </div>
      </div>
    </div>
  );
};

export default Header;
