import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import MobileHeaderSmall from '../../components/MobileHeaderSmall/MobileHeaderSmall';
import Organizer from '../../components/Organizer/Organizer';
import WhyJoinComponent from '../../components/WhyJoinComponent/WhyJoinComponent';
import './Sponsors.scss';
import { useInView } from 'react-intersection-observer';

const Sponsors = () => {
  const [start, setStart] = useState(false);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  useEffect(() => {
    if (entry) {
      setStart(true);
    }
  }, [inView]);

  return (
    <div className="sponsors">
      <Header main={false} />
      <MobileHeaderSmall main={false} />
      <Organizer titleClass={'organizer-page'} />
      <div ref={ref}>
        <WhyJoinComponent start={start} />
      </div>
      <Footer />
    </div>
  );
};

export default Sponsors;
