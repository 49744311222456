import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import Gallery from '../../components/Gallery/Gallery';
import Header from '../../components/Header/Header';
import ListOfParticipants from '../../components/ListOfParticipants/ListOfParticipants';
import MobileHeaderSmall from '../../components/MobileHeaderSmall/MobileHeaderSmall';
import ParticipationMobileApp from '../../components/ParticipationMobileApp/ParticipationMobileApp';
import './Participants.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const Participants = () => {
  const [showBtn, setShowBtn] = useState(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);

  return (
    <div className="participants">
      <Header main={false} />
      <MobileHeaderSmall main={false} />
      <ListOfParticipants
        backgroudColor={'#FFFFFF'}
        titleColor={'#0D4077'}
        nameColor={'#0D4077'}
        page={'participants'}
      />
      <ParticipationMobileApp />
      <Gallery galleryClass={'participants-gallery'} />
      <Footer />
      {showBtn && (
        <button onClick={goToTop} className="btn-top">
          <FontAwesomeIcon icon={faArrowUp} color="white" className="icon" />
        </button>
      )}
    </div>
  );
};

export default Participants;
