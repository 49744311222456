import { NavigationData } from '../../data/NavigationData';
import './Sidebar.scss';
import { Squash as Hamburger } from 'hamburger-react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = (props: any) => {
  return (
    <div className="sidebar">
      <div className="burger-menu">
        <Hamburger toggled={true} toggle={props.handleSidebarOpen} color="#C7363D" />
      </div>
      <div className="nav-container">
        <div className="nav">
          {NavigationData.map((el: any, i: number) => {
            return (
              <Link
                to={el.link}
                key={i + 1}
                className={`nav-item ${el.link === location.pathname && 'activeClass'}`}>
                {el.name}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
