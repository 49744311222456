import './ParticipationMobileApp.scss';
import googlePlay from '../../assets/google-play.png';
import { Link } from 'react-router-dom';

const ParticipationMobileApp = () => {
  return (
    <div className="participation-mobile-app">
      <div className="left-container">
        <div className="inner-container">
          <p className="title">Prijava za učešće</p>
          <p className="text">Iznos kotizacije 300,00 € sa uračunatim PDV-om kurs 1€ = 117.5 din</p>
          <div className="btn-container">
            <Link to={'/sign-up'} className="btn">
              PRIJAVA
            </Link>
          </div>
        </div>
      </div>
      <div className="right-container">
        <div className="inner-container">
          <p className="title">Mobilna aplikacija</p>
          <p className="text">Uskoro će biti dostupna mobilna aplikacija konferencije</p>
          <div className="btn-container">
            <img className="img" src={googlePlay} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipationMobileApp;
