import './Overlay.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Overlay = (props: any) => {
  return (
    <div className="overlay">
      <div className="icon-container">
        <FontAwesomeIcon
          onClick={props.handleModal}
          icon={faTimes}
          color={'white'}
          className="close-icon"
        />
      </div>
    </div>
  );
};

export default Overlay;
