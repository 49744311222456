import * as pdfjsLib from 'pdfjs-dist';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { useEffect, useRef } from 'react';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

export interface PdfPreviewProps {
  pdfDefinition: any;
  documentName?: any;
  downloadDocument?: any;
  printDocument?: any;
}

export const PdfPreview = (p: PdfPreviewProps) => {
  const pdfDefinition = p.pdfDefinition;

  // private pdfContainerRef: any
  let pdfGeneratingQueueLength = 0;
  let pdfGenerating: boolean | undefined;
  let currentGeneratedPdf: any;

  const pdfContainerRef = useRef(null);

  useEffect(() => {
    if (p.downloadDocument) {
      p.downloadDocument.current = downloadDocument;
    }
  }, [p.pdfDefinition, p.documentName]);

  useEffect(() => {
    if (pdfDefinition && pdfContainerRef.current) {
      generatePdf();
    }
  }, [pdfDefinition, pdfContainerRef]);

  const downloadDocument = () => {
    currentGeneratedPdf = pdfMake.createPdf(pdfDefinition);
    const fileName = p.documentName ? p.documentName : `Agenda.pdf`;
    currentGeneratedPdf.download(fileName);
  };

  const generatePdf = () => {
    pdfGeneratingQueueLength++;
    if (!pdfGenerating) {
      pdfGenerating = false; // always generate!!!
      currentGeneratedPdf = pdfMake.createPdf(pdfDefinition);
      currentGeneratedPdf.getBuffer(onPdfGenerated);
    } else {
      // this.setState({...this.state, pdfGenerating: true})
      // this.state.pdfGeneratingQueueStatus = true
      //let me = this
      setTimeout(() => {
        if (!pdfGenerating && pdfGeneratingQueueLength > 0) {
          // this.setState({...this.state, pdfGenerating: false})
          // me.state.pdfGeneratingQueueStatus = false
          generatePdf();
        }
      }, 3000);
    }
  };

  const onPdfGenerated = (buff: any) => {
    if (isArrayBuffer(buff)) {
      // Fetch the PDF document from the URL using promises.
      const loadingTask = pdfjsLib.getDocument(buff);
      loadingTask.promise.then((pdf: any) => {
        clearPdfCanvasContainer();
        for (let i = 1, l = pdf.numPages; i <= l; i++) {
          const pageNum = i;
          pdf.getPage(i).then((page: any) => {
            printPdfPageOnCanvas.apply(this, [page]);
          });
        }
        // me.setState({...this.state, pdfGenerating: false})
        // me.state.pdfGenerating = false
        pdfGenerating = false;
        pdfGeneratingQueueLength--;
      });
    }
  };

  const isArrayBuffer = (v: any): boolean => {
    return typeof v === 'object' && v !== null && v.byteLength !== undefined;
  };

  const printPdfPageOnCanvas = (page: any) => {
    // eslint-disable-next-line prefer-const
    let scale = 1,
      // eslint-disable-next-line prefer-const
      viewport = page.getViewport({ scale: scale }),
      canvas: any,
      canvasContainer: any,
      renderContext;

    // Prepare canvas using PDF page dimensions.
    // eslint-disable-next-line prefer-const
    canvas = document.createElement('canvas');
    canvas.height = scale * 595 * (297 / 210);
    canvas.width = scale * 600;
    canvas.style.border = '2px solid #aaaaaa';
    canvas.style.marginLeft = '0px';
    canvas.style.marginRight = '0px';
    canvas.style.marginTop = '0px';
    canvas.style.marginBottom = '0px';

    // eslint-disable-next-line prefer-const
    canvasContainer = pdfContainerRef;

    if (canvasContainer.current) {
      canvasContainer.current.appendChild(canvas);
    }

    // Render PDF page into canvas context.
    // eslint-disable-next-line prefer-const
    renderContext = {
      canvasContext: canvas.getContext('2d'),
      viewport: viewport
    };
    page.render(renderContext);
  };

  const clearPdfCanvasContainer = () => {
    // eslint-disable-next-line prefer-const
    let canvasContainer: any = pdfContainerRef;
    if (canvasContainer.current) {
      while (canvasContainer.current.firstChild) {
        canvasContainer.current.removeChild(canvasContainer.current.firstChild);
      }
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', marginLeft: '0px' }}>
      <div
        ref={pdfContainerRef}
        style={{ padding: '0px', width: '100%', overflow: 'auto', margin: '0 auto' }}>
        Pdf se učitava
      </div>
    </div>
  );
};
