import { UpdateEventDto } from './Event';
import { UpdateAccommodationDto } from './Accommodation';
import { UpdateRoomType } from './RoomType';

export enum RegistrationStatus {
  Pending = 'Na čekanju',
  Rejected = 'Odbijeno',
  Approved = 'Odobreno',
  Reserved = 'Rezervisano',
  Paid = 'Uplaćeno'
}

export interface Registration {
  id?: string;
  event: UpdateEventDto; //dogadjaj
  date: Date; //datum prijave
  status?: RegistrationStatus;
  lastStatusChange?: Date; //datum i vreme poslednje promene statusa ucesnika fizicko lice
  isCompanyRegistration: boolean; //true - pravno lice, false - fizicko lice
  isAccommodationRequired: boolean; //true - potreban smestaj, false - nije
  nameAndSurname?: string;
  type?: string; //tip ucesnika (predavac...)
  email?: string;
  phone: string;
  address?: string;
  personalId?: string; //JMBG
  nationalId?: string; //br. licne karte ili pasosa
  companyName?: string;
  isInsuranceCompany?: boolean; //true - jeste osiguravajuce drustvo, false - nije
  companyAddress?: string;
  vatNumber?: string; //PIB
  companyId?: string; //maticni broj
  companyContactPerson?: string;
  totalPrice?: number; //ukupna cena
  note?: string; //komentar za ucesnika
  participantAccommodation?: {
    //smestaj za fizicko lice i dodatne osobe
    accommodation: UpdateAccommodationDto;
    roomType: UpdateRoomType;
    numberOfPersons: number;
    dateFrom: Date;
    dateTo: Date;
    numberOfDays: number;
    priceOfAccommodation: number;
    parkingPlaces?: number;
    note?: string;
    additionalPerson: {
      nameAndSurname: string;
      type: string;
      address?: string;
      email?: string;
      phone?: string;
      nationalId: string;
      personalId: string;
      status?: RegistrationStatus;
      lastStatusChange?: Date; //datum i vreme poslednje promene statusa ucesnika fizicko lice
      note?: string; //komentar za ucesnika
    }[];
  };
  participantCompany?: {
    //lista ucesnika pravnog lica ako nije potreban smestaj
    nameAndSurname: string;
    type: string;
    address?: string;
    email: string;
    phone: string;
    nationalId?: string; //br licne karte/pasosa
    personalId?: string; //jmbg
    status?: RegistrationStatus;
    lastStatusChange?: Date; //datum i vreme poslednje promene statusa ucesnika fizicko lice
    note?: string; //komentar za ucesnika
  }[];
  participantCompanyWithAccommodation?: {
    //lista ucesnika pravnog lica, ako je potreban smestaj
    accommodation: UpdateAccommodationDto;
    roomType: UpdateRoomType;
    numberOfPersons: number;
    dateFrom: Date;
    dateTo: Date;
    numberOfDays: number;
    priceOfAccommodation: number;
    parkingPlaces?: number;
    note?: string;
    persons: {
      nameAndSurname: string;
      type: string;
      address?: string;
      email: string;
      phone: string;
      nationalId: string;
      personalId: string;
      uosPayment?: boolean; //uos placa za vip i sponzorisane tipove korisnika
      status?: RegistrationStatus;
      lastStatusChange?: Date; //datum i vreme poslednje promene statusa ucesnika fizicko lice
      note?: string; //komentar za ucesnika
    }[];
  }[];
}

export interface RegistrationDto {
  id?: string;
  event: UpdateEventDto; //dogadjaj
  date: Date; //datum prijave
  status?: RegistrationStatus;
  lastStatusChange?: Date; //datum poslednje izmene statusa prijave
  isAccommodationRequired: boolean; //true - potreban smestaj, false - nije
  nameAndSurname: string;
  type: string; //tip ucesnika (predavac...)
  email: string;
  phone: string;
  address?: string;
  personalId?: string; //JMBG
  nationalId?: string; //br. licne karte ili pasosa
  personsAccommodationJSON?: string; //podaci o smestaju i dodatnim ucesnicima u JSON-u, TODO: razraditi detaljnije...
  note?: string; //napomena
}

export interface RegistrationCompanyDto {
  id?: string;
  event: UpdateEventDto; //dogadjaj
  date: Date; //datum prijave
  lastStatusChange?: Date; //datum poslednje izmene statusa prijave
  isAccommodationRequired: boolean; //true - potreban smestaj, false - nije
  companyName: string;
  companyAddress?: string;
  isInsuranceCompany?: boolean; //true - jeste osiguravajuce drustvo, false - nije
  vatNumber?: string; //PIB
  companyId?: string; //maticni broj
  companyEmail?: string;
  companyPhone?: string;
  personsAccommodationJSON: string; //podaci o smestaju i dodatnim ucesnicima u JSON-u, TODO: razraditi detaljnije...
}

export enum CreatedType {
  Site = 'Sajt',
  Administration = 'Administracija'
}
