import React, { useState } from 'react';
import './MobileHeaderSmall.scss';
import UOSLogo from '../../assets/UOS-logo-color.png';
import { Squash as Hamburger } from 'hamburger-react';
import Sidebar from '../Sidebar/Sidebar';
import { Link } from 'react-router-dom';

const MobileHeaderSmall = (props: any) => {
  const [isOpen, setOpen] = useState(false);

  const handleSidebarOpen = () => {
    return setOpen(false);
  };

  return (
    <div className={props.main ? 'mobile-header-small' : 'mobile-header-small border-mobile '}>
      {isOpen && <Sidebar handleSidebarOpen={handleSidebarOpen} isOpen={isOpen} />}
      <div className="inner-container">
        <div className="navigation">
          <Link to={'/'}>
            <img className="image" src={UOSLogo} />
          </Link>
          <div className="burger-menu">
            {!isOpen && <Hamburger toggled={false} toggle={setOpen} color={'#C7363D'} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileHeaderSmall;
