import { Route, Routes } from 'react-router-dom';
import Main from '../pages/Main/Main';
import Accommodation from '../pages/Accommodation/Accommodation';
import Agenda from '../pages/Agenda/Agenda';
import Archive from '../pages/Archive/Archive';
import Contact from '../pages/Contact/Contact';
import Participants from '../pages/Participants/Participants';
import Sponsors from '../pages/Sponsors/Sponsors';
import ArchiveSingleView from '../pages/ArchiveSingleView/ArchiveSingleView';
import SignUpPage from '../pages/SignUpPage/SignUpPage';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="accommodation" element={<Accommodation />} />
      <Route path="agenda" element={<Agenda />} />
      <Route path="archive" element={<Archive />} />
      <Route path="archive/:id" element={<ArchiveSingleView />} />
      <Route path="contact" element={<Contact />} />
      <Route path="participants" element={<Participants />} />
      <Route path="sponsors" element={<Sponsors />} />
      <Route path="sign-up" element={<SignUpPage />} />
    </Routes>
  );
};
