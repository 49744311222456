import test1 from '../assets/test1.png';
import test2 from '../assets/test2.png';
import test3 from '../assets/test3.png';
import test4 from '../assets/test4.png';
import test5 from '../assets/test5.png';
import test6 from '../assets/test6.png';
import test7 from '../assets/test7.png';
import test8 from '../assets/test8.png';
import test9 from '../assets/test9.png';
import test10 from '../assets/test10.png';
import test11 from '../assets/test11.png';
import test12 from '../assets/test12.png';

export const AgendaTabDataSecond: any = [
  [
    {
      title: '10:00 Svečano otvaranje',
      dataList: [
        {
          time: '10:00',
          context: '„Salesforce“ CRM u Generali osiguranju',
          company: 'KAMELEON SOLUTIONS',
          image: [test1]
        },
        {
          time: '10:20',
          context: 'Monitoring alat i biznis analitika u industriji osiguranja',
          company: 'SAGA NEW FRONTIER GROUP',
          image: [test2]
        },
        {
          time: '10:40',
          context:
            'Koristi od uspostavljanja ekosistema za e-poslovanje kao jedna od važnijih koraka do konkurentske prednosti',
          company: 'SETCCE',
          image: [test3]
        },
        {
          time: '11:00',
          context: 'ATOS - Prezentacija',
          company: 'ATOS',
          image: [test4, test5, test6]
        },
        {
          time: '11:20',
          context: 'Softverski roboti: Digitalna radna površina',
          company: 'DEX',
          image: [test7]
        },
        {
          time: '11:40',
          context: 'Comtrade Cyber Operations',
          company: 'COMTRADE',
          image: [test8]
        },
        {
          time: '12:00',
          context: 'Prezentacija',
          company: 'AIGO',
          image: [test9, test10, test11]
        }
      ]
    }
    // {
    //   title: '12:20 Kafe pauza',
    //   dataList: [
    //     {
    //       time: '13:00',
    //       context:
    //         'Okrugli sto: IT Izazovi u doba pandemije – Nebojša Veličković, Vidan Marković, Miodrag Jovanović, Branko Pavlović, Nenad Nenadić, Aleksandar Đoković',
    //       image: [test1, test2, test3]
    //     },
    //     {
    //       time: '14:00',
    //       context: 'Kraj drugog dana konferencije, Pansionski ručak za učesnike konferencije',
    //       image: [test10, test11, test12]
    //     }
    //   ]
    // },
    // {
    //   title:
    //     '20:00 časova za učesnike konferencije planiran je svečana večeru uz muziku u hotelu DUNAV - Zlatibor',
    //   dataList: []
    // }
  ],
  [
    {
      title: '10:00 Nastavak konferencije',
      dataList: [
        {
          time: '10:20',
          context:
            'Evideus - sistem za „online“ poslovanje, digitalizaciju i arhiviranje dokumenata',
          company: 'NORMASOFT'
        },
        {
          time: '10:40',
          context: 'Prezentacija',
          company: 'NORMASOFT'
        },
        {
          time: '11:00',
          context:
            'Automatizacija poslovnih procesa i video identifikacija u industriji osiguranja',
          company: 'NORMASOFT'
        },
        {
          time: '11:20',
          context: 'Da li će Globos biti srpski Lemonade ',
          company: 'NORMASOFT'
        },
        {
          time: '12:00',
          context: 'NORMASOFT'
        }
      ]
    }
    // {
    //   title: 'NAKON 12:00 CASOVA ZA UCESNIKE KONFERENCIJE PLANIRANA JE PANSIONSKA VECERA',
    //   dataList: []
    // }
  ],
  [
    {
      title: '10:00 Nastavak konferencije',
      dataList: [
        {
          time: '13:20',
          context:
            'Evideus - sistem za „online“ poslovanje, digitalizaciju i arhiviranje dokumenata',
          company: 'UOS'
        },
        {
          time: '13:40',
          context: 'Prezentacija',
          company: 'UOS'
        },
        {
          time: '14:00',
          context:
            'Automatizacija poslovnih procesa i video identifikacija u industriji osiguranja',
          company: 'UOS'
        },
        {
          time: '14:20',
          context: 'Da li će Globos biti srpski Lemonade ',
          company: 'UOS'
        },
        {
          time: '15:00',
          context: 'UOS'
        }
      ]
    }
    // {
    //   title: 'NAKON 15:00 CASOVA ZA UCESNIKE KONFERENCIJE PLANIRANA JE PANSIONSKA VECERA',
    //   dataList: []
    // }
  ]
];
