import { AgendaTemplateData } from './AgendaTemplateData';
import { headerLogo, backgroundLogo } from '../constants/PdfReport';
import moment from 'moment';
import htmlToPdfMake from 'html-to-pdfmake';

const parseHtmlToPdfMake = (htmlContent: any) => {
  return htmlToPdfMake(htmlContent); // Uklonjena 'useCss' opcija
};

export const AgendaImportTemplate = (data: AgendaTemplateData) => {
  // Generisanje niza datuma između početnog i završnog datuma iz podataka
  const dateArray = [];
  let currentDate = moment(data.dateFrom); /// izmjeni datum dinamicki da bude
  const stopDate = moment(data.dateTo);
  while (currentDate <= stopDate) {
    dateArray.push({ date: moment(currentDate).format('DD.MM.YYYY.') });
    currentDate = moment(currentDate).add(1, 'days');
  }

  // Grupisanje agende po danima
  const sortedAgenda = dateArray.map((day) => {
    const dailyAgenda = data.agendas
      .filter((agenda) => moment(agenda.date).format('DD.MM.YYYY.') === day.date)
      .sort((a, b) => new Date(a.time[0]).getTime() - new Date(b.time[0]).getTime());

    return {
      date: day.date,
      events: dailyAgenda
    };
  });

  return {
    background: [
      {
        image: backgroundLogo,
        width: 580,
        alignment: 'center',
        absolutePosition: { x: 0, y: 150 }
      }
    ],
    header: [],
    footer: [],
    content: [
      {
        image: headerLogo,
        width: 150, // Širina logotipa u zaglavlju
        alignment: 'center'
      },
      {
        text: 'AGENDA',
        alignment: 'center',
        style: 'title',
        margin: [0, 20, 0, 0]
      },
      {
        text: `${data.title}, ${data.place}`,
        alignment: 'center',
        style: 'title',
        margin: [10, 10, 10, 10]
      },
      {
        text: `${moment(data.dateFrom).format('DD.MM.YYYY.')} - ${moment(data.dateTo).format(
          'DD.MM.YYYY.'
        )}`,
        alignment: 'center',
        style: 'title',
        margin: [0, 0, 0, 10]
      },
      ...sortedAgenda.map((dayAgenda, index) => {
        return [
          {
            text: `Dan - ${index + 1}, ${dayAgenda.date}`,
            style: 'dayHeader',
            color: '#ffffff', // Bela boja teksta
            alignment: 'center', // Centrirano poravnanje
            background: '#C7363D', // Crvena pozadina za dan zaglavlje
            margin: [0, 10, 0, 10], // Unutrašnje margine za bolji izgled
            width: '100%', // Crveni blok zauzima celu širinu
            table: {
              fillColor: '#C7363D',
              widths: ['100%'],
              defaultBorder: false,
              body: [
                [
                  {
                    text: `Dan - ${index + 1}, ${dayAgenda.date}`,
                    style: 'dayHeader',
                    alignment: 'center',
                    color: '#ffffff', // Bela boja teksta
                    background: '#C7363D', // Crvena pozadina za dan zaglavlje
                    colSpan: 1, // Nema kolona u tabeli,
                    fillColor: '#C7363D',
                    widths: ['100%'] // Širina tabele na 100%
                  }
                ]
              ],

              layout: {
                defaultBorder: false
              }
            }
          },
          ...dayAgenda.events.map((event) => ({
            columns: [
              ///const parsedTitle = parseHtmlToPdfMake(event.title);
              {
                text: moment(event.time[0]).format('HH:mm'),
                width: '10%',
                style: 'eventTime',
                margin: [0, 0, 10, 0] // Nema margina oko teksta
              },
              {
                text: `${event.title} ${event.instructorName ? `, ${event.instructorName}` : ''} ${
                  event.instructorCompanyName ? `, ${event.instructorCompanyName}` : ''
                }`,
                width: '100%',
                style: /<strong>/.test(event.content as string) ? 'eventTitleBold' : 'eventTitle', // Uslovna logika za boldovanje
                margin: [0, 0, 0, 0] // Nema margina oko teksta
              }
            ],
            margin: [0, 2, 0, 2],
            lineHeight: 1
          }))
        ];
      })
    ],
    styles: {
      title: {
        fontSize: 15,
        alignment: 'center',
        bold: true
      },
      dayHeader: {
        fontSize: 15,
        bold: true,
        alignment: 'center',
        background: '#C7363D', // Pozadina zaglavlja
        color: '#ffffff'
      },
      eventTime: {
        fontSize: 11,
        alignment: 'right'
      },
      eventTitle: {
        fontSize: 11,
        alignment: 'left',
        bold: false
      },
      eventTitleBold: {
        fontSize: 11,
        alignment: 'left',
        bold: true // Ovo će učiniti boldovanim kada `instructorName` nije prisutan
      }
    }
  };
};
