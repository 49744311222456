import { useEffect, useState } from 'react';

import moment from 'moment';
import dayjs from 'dayjs';
import { getEvent } from '../../services/EventService';

const useFetchOneEvent = (id: any) => {
  const [dataEvent, setData] = useState<any>(undefined);

  useEffect(() => {
    if (id) {
      getEvent(id).then((response) => {
        const data = response.data.result;

        const formattedDateFrom = moment(data?.dateFrom).format('YYYY-MM-DD');
        const formattedDateTo = moment(data?.dateTo).format('YYYY-MM-DD');

        const uniqueAccommodations: any[] = [];

        data.roomTypesForEvent?.forEach((roomTypeEvent: any) => {
          const existingAccommodation = uniqueAccommodations.find(
            (accommodation: any) => accommodation?.id === roomTypeEvent?.roomType?.accommodation?.id
          );

          if (!existingAccommodation) {
            const newAccommodation = {
              ...roomTypeEvent.roomType.accommodation,
              roomTypes: []
            };

            const roomTypeIds = roomTypeEvent.roomType.id;

            const matchedRoomTypes = roomTypeEvent?.roomType?.accommodation?.roomType.filter(
              (roomType: any) => roomTypeIds.includes(roomType.id)
            );

            if (matchedRoomTypes?.length > 0) {
              newAccommodation.roomTypes = matchedRoomTypes.map((roomType: any) => ({
                ...roomTypeEvent,
                accommodation: roomTypeEvent.roomType.accommodation,
                capacity: roomTypeEvent.roomType.capacity,
                name: roomTypeEvent.roomType.name,
                roomType: roomType
              }));

              uniqueAccommodations.push(newAccommodation);
            }
          } else {
            const existingAccommodationIndex = uniqueAccommodations.findIndex(
              (accommodation: any) => accommodation.id === roomTypeEvent.roomType.accommodation.id
            );

            const roomTypeIds = roomTypeEvent.roomType.id;

            const matchedRoomTypes = roomTypeEvent.roomType.accommodation.roomType.filter(
              (roomType: any) => roomTypeIds.includes(roomType.id)
            );

            if (matchedRoomTypes.length > 0) {
              uniqueAccommodations[existingAccommodationIndex].roomTypes.push(
                ...matchedRoomTypes.map((roomType: any) => ({
                  ...roomTypeEvent,
                  accommodation: roomTypeEvent.roomType.accommodation,
                  capacity: roomTypeEvent.roomType.capacity,
                  name: roomTypeEvent.roomType.name,
                  roomType: roomType
                }))
              );
            }
          }
        });

        const transformedData = {
          ...data,
          date: [dayjs(formattedDateFrom), dayjs(formattedDateTo)],
          accommodations: uniqueAccommodations
        };

        setData(transformedData);
      });
    }
  }, [id]);

  return [dataEvent];
};

export default useFetchOneEvent;
