import { NavigationData } from '../../data/NavigationData';
import { Link, useLocation } from 'react-router-dom';
import './Navigation.scss';

const Navigation = () => {
  const location = useLocation();

  return (
    <div className="navigation-inner-container">
      {NavigationData.map((el: any, i: number) => {
        return (
          <Link
            to={el.link}
            key={i + 1}
            className={`nav-item ${el.link === location.pathname && 'activeClass'}`}>
            {el.name}
          </Link>
        );
      })}
    </div>
  );
};

export default Navigation;
