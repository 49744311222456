export const AgendaTabData: any = [
  [
    {
      title: '10:00 Svečano otvaranje',
      dataList: [
        {
          time: '18:20',
          context:
            'Evideus - sistem za „online“ poslovanje, digitalizaciju i arhiviranje dokumenata',
          company: 'NORMASOFT'
        },
        {
          time: '18:40',
          context: 'Prezentacija',
          company: 'ATOS'
        },
        {
          time: '19:00',
          context:
            'Automatizacija poslovnih procesa i video identifikacija u industriji osiguranja',
          company: 'SAGA NEW FRONTIER GROUP'
        },
        {
          time: '19:20',
          context: 'Da li će Globos biti srpski Lemonade ',
          company: 'GLOBOS osiguranje'
        },
        {
          time: '20:00',
          context: 'Kraj prvog dana konferencije'
        }
      ]
    }
    // {
    //   title: 'NAKON 20:00 CASOVA ZA UCESNIKE KONFERENCIJE PLANIRANA JE PANSIONSKA VECERA',
    //   dataList: []
    // }
  ],
  [
    {
      title: '10:00 Nastavak konferencije',
      dataList: [
        {
          time: '10:20',
          context:
            'Evideus - sistem za „online“ poslovanje, digitalizaciju i arhiviranje dokumenata',
          company: 'NORMASOFT'
        },
        {
          time: '10:40',
          context: 'Prezentacija',
          company: 'NORMASOFT'
        },
        {
          time: '11:00',
          context:
            'Automatizacija poslovnih procesa i video identifikacija u industriji osiguranja',
          company: 'NORMASOFT'
        },
        {
          time: '11:20',
          context: 'Da li će Globos biti srpski Lemonade ',
          company: 'NORMASOFT'
        },
        {
          time: '12:00',
          context: 'NORMASOFT'
        }
      ]
    }
    // {
    //   title: 'NAKON 12:00 CASOVA ZA UCESNIKE KONFERENCIJE PLANIRANA JE PANSIONSKA VECERA',
    //   dataList: []
    // }
  ],
  [
    {
      title: '10:00 Nastavak konferencije',
      dataList: [
        {
          time: '13:20',
          context:
            'Evideus - sistem za „online“ poslovanje, digitalizaciju i arhiviranje dokumenata',
          company: 'UOS'
        },
        {
          time: '13:40',
          context: 'Prezentacija',
          company: 'UOS'
        },
        {
          time: '14:00',
          context:
            'Automatizacija poslovnih procesa i video identifikacija u industriji osiguranja',
          company: 'UOS'
        },
        {
          time: '14:20',
          context: 'Da li će Globos biti srpski Lemonade ',
          company: 'UOS'
        },
        {
          time: '15:00',
          context: 'UOS'
        }
      ]
    }
    // {
    //   title: 'NAKON 15:00 CASOVA ZA UCESNIKE KONFERENCIJE PLANIRANA JE PANSIONSKA VECERA',
    //   dataList: []
    // }
  ]
];
