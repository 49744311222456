import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { AccommodationData } from '../../data/AccommodationData';
import './Accommodation.scss';
import Wifi from '../../assets/WiFi.png';
import TV from '../../assets/TV.png';
import SatelliteCable from '../../assets/Satelitska-kablovska.png';
import RoomService from '../../assets/Room-service.png';
import Caffe from '../../assets/Stanica-sa-kafom-i-cajem-ketler.png';
import Furniture from '../../assets/Garnitura.png';
import Duty from '../../assets/24-satna-usluga.png';
import Sef from '../../assets/Sef.png';
import ChildrenBed from '../../assets/Deciji-krevet.png';
import MobileHeaderSmall from '../../components/MobileHeaderSmall/MobileHeaderSmall';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { getEvent } from '../../services/EventService';
import { AccommodationDto } from '../../models/Accommodation';
import { CreateRoomType } from '../../models/RoomType';
import dayjs from 'dayjs';
import moment from 'moment';
import accommodation1 from '../../assets/accommodation1.png';
import { Spin } from 'antd';
import { currentEventId } from '../../config/config';

const Accommodation = () => {
  const [activeSpinner, setActiveSpinner] = useState<boolean>(true);
  const [showBtn, setShowBtn] = useState(false);
  const [idEvent, setIdEvent] = useState<string>(currentEventId);
  const [oneAccommodation, setOneAccommodation] = useState<any>(undefined);

  useEffect(() => {
    if (idEvent !== undefined) {
      getEvent(idEvent).then((response) => {
        const data = response.data.result;

        const formattedDateFrom = moment(data?.dateFrom).format('YYYY-MM-DD');
        const formattedDateTo = moment(data?.dateTo).format('YYYY-MM-DD');

        const uniqueAccommodations: AccommodationDto[] = [];

        data.roomTypesForEvent?.forEach((roomTypeEvent: any) => {
          const existingAccommodation = uniqueAccommodations.find(
            (accommodation: AccommodationDto) =>
              accommodation?.id === roomTypeEvent?.roomType?.accommodation?.id
          );

          if (!existingAccommodation) {
            const newAccommodation = {
              ...roomTypeEvent.roomType.accommodation,
              roomTypes: []
            };

            const roomTypeIds = roomTypeEvent.roomType.id;

            const matchedRoomTypes = roomTypeEvent?.roomType?.accommodation?.roomType.filter(
              (roomType: CreateRoomType) => roomTypeIds.includes(roomType.id)
            );

            if (matchedRoomTypes?.length > 0) {
              newAccommodation.roomTypes = matchedRoomTypes.map((roomType: CreateRoomType) => ({
                ...roomTypeEvent,
                accommodation: roomTypeEvent.roomType.accommodation,
                capacity: roomTypeEvent.roomType.capacity,
                name: roomTypeEvent.roomType.name
                // roomType: roomType
              }));

              uniqueAccommodations.push(newAccommodation);
            }
          } else {
            const existingAccommodationIndex = uniqueAccommodations.findIndex(
              (accommodation: AccommodationDto) =>
                accommodation.id === roomTypeEvent.roomType.accommodation.id
            );

            const roomTypeIds = roomTypeEvent.roomType.id;

            const matchedRoomTypes = roomTypeEvent.roomType.accommodation.roomType.filter(
              (roomType: CreateRoomType) => roomTypeIds.includes(roomType.id)
            );

            if (matchedRoomTypes.length > 0) {
              uniqueAccommodations[existingAccommodationIndex].roomTypes.push(
                ...matchedRoomTypes.map((roomType: CreateRoomType) => ({
                  ...roomTypeEvent,
                  accommodation: roomTypeEvent.roomType.accommodation,
                  capacity: roomTypeEvent.roomType.capacity,
                  name: roomTypeEvent.roomType.name,
                  roomType: roomType
                }))
              );
            }
          }
        });

        const transformedData = {
          ...data,
          date: [dayjs(formattedDateFrom), dayjs(formattedDateTo)],
          accommodations: uniqueAccommodations
        };

        const transformAccommdationsData = transformedData.accommodations.map(
          (el: any, i: number) => {
            if (i === 0) {
              return {
                ...el,
                text: 'Dobrodošli u hotel Moskva, znamenitost Beograda, jedan od najznačajnijih arhitektonskih dragulja srpske prestonice, izgrađen u stilu ruske secesije. Zgrada hotela je pod zaštitom države od 1968. kao spomenik kulture, a od 1979. kao kulturno dobro od velikog značaja. Ovo velelepno zdanje otvorio je kralj Petar I Karađorđević u januaru 1908. godine. Tradicija postojanja i poslovanja duža od jednog veka učinila je da ovaj hotel poseti preko 40 miliona gostiju, uključujući poznata imena poput: Alberta Ajnštajna, Ane Pavlove, Leonida Brežnjeva, Indire Gandi, Reja Čarlsa, Roberta De Nira, Bred Pita, Maksima Gorkog, Kirka Daglasa, Majkla Daglasa, Mile Jovovič i mnogih drugih.'
              };
            } else if (i === 1) {
              return {
                ...el,
                text: 'Četiri različita tipa soba i apartmana, dizajnirani su tako da svaki tip priča svoju priču i daje dozu luksuza ambijentu u kom se nalazi. Pogled na centar Zlatibora i čist vazduh  planine oko Vas, doprinose potpunom komforu kroz upotrebu najmodernijih tehnologija  izgradnje i dizajna. Vaš stil i karakter ogleda se u stilu Vašeg okruženja, i upravo zbog  toga mi nudimo različite opcije. Standardne sobe su dizajnirane tako da u našim Rustik  sobama možete osetiti bliskost sa prirodom i tradicijom, dok u Elegant sobi možete  podrediti sve sopstvenom miru i luksuzu. U našim Superior sobama, prostor možete  iskoristiti kako želite. Uz moderno opremljena kupatila sa kadom, napravite svoj mini SPA.'
              };
            } else if (i === 2) {
              return {
                ...el,
                text: 'Apartmani Kopaonik - Najveća ponuda smeštaja na Kopaoniku. Velika posećenost planine prirodno je uticala da turistička smeštajna ponuda razvije kapacitete u svim pravcima. Osim povećanja broja smeštajnih jedinica, Kopaonik je značajno poboljšao i njihov kvalitet. Zahvaljujući tome, apartmani Kopaonik omogućiće vam da vrlo lako pronađete savršen planinski prostor za svoj boravak. Bilo da više volite boravak u centru ili ste više za mirniji deo planine, na raspolaganju su vam moderne i kvalitetne smeštajne jedinice. Rezervišite onu koja ispunjava sve vaše zahteve i provedite ugodan planinski odmor.'
              };
            } else if (i === 3) {
              return {
                ...el,
                text: 'test'
              };
            } else {
              return el;
            }
          }
        );

        setOneAccommodation(transformAccommdationsData);
        setActiveSpinner(false);
      });
    }
  }, [idEvent]);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);

  return (
    <div className="accommodation">
      <Header main={false} />
      <MobileHeaderSmall main={false} />
      <Spin className="spinner" spinning={activeSpinner}></Spin>
      {!activeSpinner && (
        <div className="container">
          <p className="title">Smeštaj</p>
          <div className="apartman-list">
            {oneAccommodation?.map((el: any, i: number) => {
              return (
                <div key={i} className="single-row-container">
                  <p className="subtitle">{el.name.toUpperCase()}</p>
                  <p className="text">{el.text}</p>
                  {el.roomTypes.map((el1: any, index: number) => {
                    return (
                      <div key={index} className="single-row">
                        <div className="img-container">
                          <div
                            className="img"
                            style={{
                              backgroundImage: `url('${accommodation1}')`
                              // backgroundImage: `url('${
                              //   el1?.roomType.photos !== undefined
                              //     ? `http://uosportal.normasoft.net/api/v1/files/storage/${el1?.roomType.photos[0].id}`
                              //     : ''
                              // }')`
                            }}></div>
                        </div>
                        <div className="description-container">
                          <p className="apartman-title">{el1?.roomType.name}</p>
                          {/* {el.subtitle && <p className="apartman-subtitle">{el.subtitle}</p>} */}
                          {/* <p className="apartman-text margin-big">{el.bed}</p> */}
                          {/* <p className="apartman-text">KVADRATURA: {el.squareFootage}</p> */}
                          <p className="apartman-text">KAPACITET: {el1?.roomType.capacity}</p>
                          {/* {el.text && <p className="apartman-text margin-small">{el.text}</p>} */}
                          <div className="icons-container">
                            <img className="icon" src={Wifi} />
                            <img className="icon" src={TV} />
                            <img className="icon" src={SatelliteCable} />
                            <img className="icon" src={RoomService} />
                            <img className="icon" src={Caffe} />
                            <img className="icon" src={Furniture} />
                            <img className="icon" src={Duty} />
                            <img className="icon" src={Sef} />
                            <img className="icon" src={ChildrenBed} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>

          <div className="btn-container">
            <Link className="btn" to={'/sign-up'}>
              REZERVIŠI
            </Link>
          </div>
        </div>
      )}
      {!activeSpinner && <Footer />}
      {showBtn && (
        <button onClick={goToTop} className="btn-top">
          <FontAwesomeIcon icon={faArrowUp} color="white" className="icon" />
        </button>
      )}
    </div>
  );
};

export default Accommodation;
