import { CreateEventDto } from '../models/Event';
import { axiosInstance } from '../AxiosDefaults';

export const getEvent = (id: string): Promise<any> => {
  const secretId = process.env.REACT_APP_SECRET_ID_HEADER;
  const clientId = process.env.REACT_APP_CLIENT_ID_SITE;
  return axiosInstance.get(`${`/events`}/${id}`, {
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
      'x-secretid': secretId,
      'x-clientid': clientId
    }
  });
};

export const getAllEvents = (): Promise<any> => {
  const secretId = process.env.REACT_APP_SECRET_ID_HEADER;
  const clientId = process.env.REACT_APP_CLIENT_ID_SITE;
  return axiosInstance.get('/events', {
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
      'x-secretid': secretId,
      'x-clientid': clientId
    }
  });
};

export const getAllLecturersInEvent = (id: string): Promise<any> => {
  const secretId = process.env.REACT_APP_SECRET_ID_HEADER;
  const clientId = process.env.REACT_APP_CLIENT_ID_SITE;
  return axiosInstance.get(`${`/events/lecturers`}/${id}`, {
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
      'x-secretid': secretId,
      'x-clientid': clientId
    }
  });
};
