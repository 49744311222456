import React, { useState, useEffect, useRef } from 'react';
import AgendaNavigation from '../../components/AgendaNavigation/AgendaNavigation';
import AgendaNavigationMobile from '../../components/AgendaNavigationMobile/AgendaNavigationMobile';
import Footer from '../../components/Footer/Footer';
import Gallery from '../../components/Gallery/Gallery';
import ListOfParticipants from '../../components/ListOfParticipants/ListOfParticipants';
import MainHeader from '../../components/MainHeader/MainHeader';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import MobileSignIn from '../../components/MobileSignIn/MobileSignIn';
import Organizer from '../../components/Organizer/Organizer';
import ParticipationMobileApp from '../../components/ParticipationMobileApp/ParticipationMobileApp';
import Venue from '../../components/Venue/Venue';
import WhyJoinComponent from '../../components/WhyJoinComponent/WhyJoinComponent';
import { AgendaTabData } from '../../data/AgendaTabData';
import './Main.scss';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import SignUpform from '../../components/SignUpform/SignUpform';
import TimeContainer from '../../components/TimeContainer/TimeContainer';

const Main = () => {
  const [start, setStart] = useState(false);
  const [showBtn, setShowBtn] = useState(false);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (entry) {
      setStart(true);
    }
  }, [inView]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);

  return (
    <div className="main">
      <MainHeader />
      <MobileHeader />
      {/* <MobileSignIn /> */}
      <TimeContainer />
      {/* <Banner /> */}
      {/* <div className="banner-btn-container">
        <Link className="btn" to={'/accommodation'}>
          POGLEDAJTE SMEŠTAJ
        </Link>
      </div>*/}
      {/* <ListOfParticipants
        backgroudColor={'#0D4077'}
        titleColor={'#FFFFFF'}
        nameColor={'#FFFFFF'}
        page={'main'}
      /> */}
      {/* <div className="temporary-container">
        <p className="text">
          PRIJAVE ZA UČEŠĆE USKORO ĆE BITI DOSTUPNE, DO TADA MOŽETE POGLEDATI ARHIVU PRETHODNIH
          GODINA NA
          <a
          className="link"
          href="http://uos.rs/%d0%b4%d0%b0%d0%bd%d0%b8-%d0%be%d1%81%d0%b8%d0%b3%d1%83%d1%80%d0%b0%d1%9a%d0%b0/">
          LINKU
          </a>
          </p>
        </div> */}
      {/* <SignUpform /> */}
      <p style={{ fontSize: 25, fontWeight: '500', color: '#0d4077' }}>Prijave su zatvorene.</p>
      <Venue />
      <div ref={ref}>
        <WhyJoinComponent start={start} />
      </div>
      <AgendaNavigation
        titleClassName={'main-agenda'}
        subtitleClassName={'main-agenda-subtitle'}
        title={'8. SRPSKI DANI OSIGURANJA'}
        AgendaTabData={AgendaTabData}
      />
      <AgendaNavigationMobile />
      {/* <ParticipationMobileApp />*/}
      <Organizer titleClass={'organizer-main'} />
      <Gallery galleryClass={'main-gallery'} />
      <Footer />
      {showBtn && (
        <button onClick={goToTop} className="btn-top">
          <FontAwesomeIcon icon={faArrowUp} color="white" className="icon" />
        </button>
      )}
    </div>
  );
};

export default Main;
