import './Organizer.scss';
import Sponzor1 from '../../assets/sponzor1.gif';
import Sponzor2 from '../../assets/sponzor2.jpg';
import Sponzor3 from '../../assets/sponzor3.jpg';
import Sponzor4 from '../../assets/sponzor4.jpg';
import Sponzor5 from '../../assets/sponzor5.jpg';
import Sponzor6 from '../../assets/sponzor6.jpg';
import Sponzor7 from '../../assets/sponzor7.jpg';
import Sponzor8 from '../../assets/sponzor8.jpg';
import Sponzor9 from '../../assets/sponzor9.jpg';
import Sponzor10 from '../../assets/sponzor10.jpg';
import Sponzor11 from '../../assets/sponzor11.jpg';
import Sponzor12 from '../../assets/sponzor12.png';
import Sponzor13 from '../../assets/sponzor13.png';
import Sponzor14 from '../../assets/sponzor14.png';

const Organizer = (props: any) => {
  return (
    <div className="organizer">
      <div className="container">
        <p className={`title ${props.titleClass}`}>Sponzori</p>
        {/* <p className="subtitle">8. SRPSKI DANI OSIGURANJA</p> */}

        <div className="img-box">
          <a href="https://saga.rs/">
            <img className="img" src={Sponzor1} />
          </a>
        </div>
        {/* <div className="img-box">
          <a href="https://www.halkbank.rs/Pocetna.1.html">
            <img className="img-second" src={Sponzor2} />
          </a>
        </div> */}
        <div className="img-list">
          <div className="img-list-wrapper">
            <a href="https://www.posted.co.rs/">
              <img className="img" src={Sponzor14} />
            </a>
          </div>
          <div className="img-list-wrapper">
            <a href="https://www.halkbank.rs/Pocetna.1.html">
              <img className="img" src={Sponzor2} />
            </a>
          </div>
          <div className="img-list-wrapper">
            <a href="https://www.srpskabanka.rs/">
              <img className="img" src={Sponzor4} />
            </a>
          </div>
          <div className="img-list-wrapper">
            <a href="https://www.procreditbank.rs/">
              <img className="img" src={Sponzor5} />
            </a>
          </div>
          {/* <div className="img-list-wrapper">
            <a href="https://www.otpbanka.rs/">
              <img className="img" src={Sponzor6} />
            </a>
          </div> */}
          <div className="img-list-wrapper">
            <a href="https://www.comtrade.com/">
              <img className="img" src={Sponzor8} />
            </a>
          </div>
          <div className="img-list-wrapper">
            <a href="https://www.halcom.com/rs/">
              <img className="img" src={Sponzor9} />
            </a>
          </div>
          <div className="img-list-wrapper">
            <a href="https://altabanka.rs/">
              <img className="img" src={Sponzor10} />
            </a>
          </div>
          <div className="img-list-wrapper">
            <a href="https://www.apibank.rs/">
              <img className="img" src={Sponzor11} />
            </a>
          </div>
          <div className="img-list-wrapper">
            <a href="https://normasoft.net/">
              <img className="img" src={Sponzor12} />
            </a>
          </div>
          <div className="img-list-wrapper">
            <a href="https://asseco.com/">
              <img className="img" src={Sponzor13} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organizer;
