export const validateStringLength = (value: any, length: number): boolean => {
  return value.length === length;
};

export const validatePersonalId = (value: any): boolean => {
  if (!validateStringLength(value, 13)) return false; //neodgovarajuca duzina jmbg

  const daysInMonth: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const now = new Date();
  const day = parseInt(value.substr(0, 2));
  const month = parseInt(value.substr(2, 2));
  let year = parseInt(value.substr(4, 3));
  if (year < 100) {
    year += 2000; //born after 2000.
  } else {
    year += 1000; //born before 2000.
  }
  const personalIdDate = new Date(year, month - 1, day).getTime();

  if (personalIdDate > now.getTime()) return false; //uneti datum Jmbg veci od danasnjeg datuma

  if (year > now.getFullYear() || year < 1900) return false; // neodgovarajuca godina (5,6,7 cifra jmbg)

  if (month > 12 || month < 1) return false; // neodgovarajuci mesec (3,4 cifra jmbg)

  if (year % 4 === 0 && (year % 100 !== 0 || year % 400 !== 0)) daysInMonth[1] = 29; //prestupna godina

  if (day < 1 || day > daysInMonth[month - 1]) return false; //neodgovarajuci dan (1,2 cifra jmbg)

  //formula za racunanje kontrolnog zbira
  let sum = 0;
  for (let i = 0; i < 6; i++) {
    sum += (7 - i) * (parseInt(value[i]) + parseInt(value[6 + i]));
  }
  const rest = sum % 11;

  if (rest === 1) return false; //nedozvoljen kontrolni broj

  let controlNumber = 11 - rest;
  if (controlNumber === 11) controlNumber = 0;

  if (controlNumber !== parseInt(value[12])) return false; //provera ispravnosti kontrolnog broja

  return true; //bez grešaka, jmbg validan
};

export const phoneRegex =
  // eslint-disable-next-line no-useless-escape
  /^(1[ \-\+]{0,3}|\+1[ -\+]{0,3}|\+1|\+)?((\(\+?1-[2-9][0-9]{1,2}\))|(\(\+?[2-8][0-9][0-9]\))|(\(\+?[1-9][0-9]\))|(\(\+?[17]\))|(\([2-9][2-9]\))|([ \-\.]{0,3}[0-9]{2,4}))?([ \-\.][0-9])?([ \-\.]{0,3}[0-9]{2,4}){2,3}$/;

export const addThousandSeparator = (num: number, separator = ',') => {
  const t = '' + num;
  const numberOfInserts = Math.floor((t.length - 1) / 3);
  if (numberOfInserts === 0) {
    return t;
  }
  let res = '';
  let cnt = 0;
  for (let i = t.length - 1; i >= 0; i--) {
    res = t[i] + res;
    cnt++;
    if (cnt % 3 === 0) {
      res = separator + res;
    }
  }
  if (res[0] === ',') res = res.slice(1);
  if (num < 0 && res[1] === '.') res = '-' + res.slice(2);
  return res;
};
