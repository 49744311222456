import { axiosInstance } from '../AxiosDefaults';
import { RegistrationDto, RegistrationCompanyDto } from '../models/Registration';

export const createRegistration = (registration: RegistrationDto): Promise<any> => {
  const secretId = process.env.REACT_APP_SECRET_ID_HEADER;
  const clientId = process.env.REACT_APP_CLIENT_ID_SITE;
  return axiosInstance.post('/registrations', registration, {
    headers: {
      'Content-Type': 'application/json',
      'x-secretid': secretId,
      'x-clientid': clientId
    }
  });
};

export const createCompanyRegistration = (registration: RegistrationCompanyDto): Promise<any> => {
  const secretId = process.env.REACT_APP_SECRET_ID_HEADER;
  const clientId = process.env.REACT_APP_CLIENT_ID_SITE;
  return axiosInstance.post('/registrations-company', registration, {
    headers: {
      'Content-Type': 'application/json',
      'x-secretid': secretId,
      'x-clientid': clientId
    }
  });
};

export const getAllCompanyInformation = (): Promise<any> => {
  const secretId = process.env.REACT_APP_SECRET_ID_HEADER;
  const clientId = process.env.REACT_APP_CLIENT_ID_SITE;
  return axiosInstance.get('/company-information', {
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
      'x-secretid': secretId,
      'x-clientid': clientId
    }
  });
};
