import React, { useState, useRef, useEffect } from 'react';
import './ListOfParticipants.scss';
import { Link } from 'react-router-dom';
import { getAllLecturersInEvent } from '../../services/EventService';
import test7 from '../../assets/test7.png';
import { currentEventId } from '../../config/config';

const ListOfParticipants = (props: any) => {
  const [idEvent, setIdEvent] = useState<string>(currentEventId);
  const [listOfParticipant, setListOfParticipant] = useState([]);
  // const [showMore, setShowMore] = useState(false);

  // const handleShowMore = () => {
  //   setShowMore(!showMore);
  // };

  // const windowSize = useRef([window.innerWidth]);

  useEffect(() => {
    getAllLecturersInEvent(idEvent).then((response: any) => {
      setListOfParticipant(response.data.result);
    });
  }, []);

  return (
    <div style={{ backgroundColor: props.backgroudColor }} className="list-participants">
      <div className="inner-container">
        <p style={{ color: props.titleColor }} className="title">
          Učesnici
        </p>
        <div className="data-list">
          {listOfParticipant.map((el: any, i: number) => {
            return (
              <div key={i} className="single-item">
                <img
                  className="image"
                  // src={`http://uosportal.normasoft.net/api/v1/files/storage/${el?.photo_id}`}
                  src={test7}
                />
                <p className="single-item-title">{el.nameAndSurname}</p>
                <p style={{ color: props.nameColor }} className="name">
                  {el.type}
                </p>
              </div>
            );
            // if (!showMore && windowSize.current[0] < 500) {
            //   if (i + 1 <= 4) {
            //     return (
            //       <div key={i} className="single-item">
            //         <img className="image" src={el.picture} />
            //         <p className="single-item-title">{el.title}</p>
            //         <p style={{ color: props.nameColor }} className="name">
            //           {el.name}
            //         </p>
            //       </div>
            //     );
            //   }
            // } else {
            //   if (!showMore && props.page === 'participants') {
            //     if (i + 1 <= 8) {
            //       return (
            //         <div key={i} className="single-item">
            //           <img className="image" src={el.picture} />
            //           <p className="single-item-title">{el.title}</p>
            //           <p style={{ color: props.nameColor }} className="name">
            //             {el.name}
            //           </p>
            //         </div>
            //       );
            //     }
            //   } else {
            //     return (
            //       <div key={i} className="single-item">
            //         <img className="image" src={el.picture} />
            //         <p className="single-item-title">{el.title}</p>
            //         <p style={{ color: props.nameColor }} className="name">
            //           {el.name}
            //         </p>
            //       </div>
            //     );
            //   }
            // }
          })}
        </div>
        {/* <div className="btn-container">
          {props.page === 'main' ? (
            <Link to="/participants" className="btn">
              POGLEDAJTE SVE UČESNIKE
            </Link>
          ) : (
            <button className="btn-participants" onClick={handleShowMore}>
              POGLEDAJTE SVE UČESNIKE
            </button>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default ListOfParticipants;
